import { useState, useEffect, useRef, ReactElement } from 'react'
import { Card, Form, OverlayTrigger, Popover } from 'react-bootstrap'

import History from '@mui/icons-material/HistoryRounded'
import Settings from '@mui/icons-material/SettingsRounded'
import Add from '@mui/icons-material/AddRounded'
import Close from '@mui/icons-material/CloseRounded'
import Remove from '@mui/icons-material/RemoveRounded'
import FilledLine from '@mui/icons-material/LegendToggleRounded'
import Line from '@mui/icons-material/TimelineRounded'
import CodeIcon from '@mui/icons-material/CodeRounded'

import { Frame, PlaybackState, SignalValue } from '../../../api/BrokerApi/types'
import { FrameFilter, SignalFilter } from '../../../types/Filters'

import { FrameParentPanelSettings, OptionalFrameParentPanelSettings, Panel } from '../../../types/Panel'
import { isFrameIdentical, isSignalIdentical } from '../../../utils/FilterUtilities'
import { Chart } from 'chart.js'
import MultiTypeSignalContainer from './MultiTypeSignalContainer'
import { ParsingType } from '../../../types/ParsingType'
import { PanelType } from '../../../types/PanelType'
import { isMappedConfiguration, viewSignalSource } from '../../../services/ConfigurationService'
import { SignalWithParsingType } from '../../../types/SignalWithParsingType'
import SignalValueHistoryModal from '../../modal/SignalValueHistoryModal'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'
import { isIframe } from '../../../utils/CloudDetails'

interface FrameParentPanelProps {
    allFrames: Array<Frame>
    panelTitle: string
    panelKey: string
    addedTimestamp: number
    savePanelFunction: (panel: Panel) => void
    frameFilter: FrameFilter
    signalFilters: Array<SignalFilter>
    removePanelFunction: (panelKey: string) => void
    setPanelsFunction: (panels: Panel[]) => void
    panels: Array<Panel>
    optionalPanelSettings: OptionalFrameParentPanelSettings | undefined
    playbackState: PlaybackState | undefined
    pauseLiveState: boolean
    isCloudBroker: boolean
}

enum SyncMode {
    TO_PLAYBACK_STATE = 'PLAYBACK',
    TO_LAST_SIGNAL_RECEIVED = 'LAST_SIGNAL',
}

const MAX_HISTORY_COUNT = 1000 // We keep the last ~1000 signals
const HISTORY_REMOVAL_COUNT = 100 // If we reach MAX_HISTORY_COUNT signals, we remove the oldest HISTORY_REMOVAL_COUNT signals
const GRAPH_DRAW_AHEAD_MS = 250 // how far outside the visible graph we will draw new signals, neccessary to reduce stuttering when streaming

const DEFAULT_CHART_TYPE_SETTING = 'line'
const DEFAULT_GRAPH_WIDTH_SETTING = 15_000
const DEFAULT_FRAME_RATE_SETTING = 3
const DEFAULT_SUGGESTED_MIN_MAX_SETTING = true
const DEFAULT_ALLOW_DOWN_SAMPLING_SETTING = true

export default function FrameParentPanel(props: FrameParentPanelProps) {
    const [showSignalValueHistoryModal, setShowSignalValueHistoryModal] = useState(false)
    const [showPopover, setShowPopover] = useState<boolean>(false)
    const [hasMappedSignalConfiguration, setHasMappedSignalConfiguration] = useState<boolean>(false)

    // User settings
    const [chartType, setChartType] = useState<'filled' | 'line'>(
        props.optionalPanelSettings?.chartType ?? DEFAULT_CHART_TYPE_SETTING
    )
    const [frameRate, setFrameRate] = useState<number>(
        props.optionalPanelSettings?.frameRate ?? DEFAULT_FRAME_RATE_SETTING
    )
    const [graphWidthInMs, setGraphWidthInMs] = useState<number>(
        props.optionalPanelSettings?.graphWidthMs ?? DEFAULT_GRAPH_WIDTH_SETTING
    )
    const [allowDownsampling, setAllowDownsampling] = useState<boolean>(
        props.optionalPanelSettings?.allowDownsampling ?? DEFAULT_ALLOW_DOWN_SAMPLING_SETTING
    )
    const [useSuggestedMinMax, setUseSuggestedMinMax] = useState<boolean>(
        props.optionalPanelSettings?.useSuggestedMinMax ?? DEFAULT_SUGGESTED_MIN_MAX_SETTING
    )

    const signalHistoryList = useRef([] as Array<SignalWithParsingType>)
    const previousPlaybackWasNotPlay = useRef<boolean>(false)

    let initializedGraphs: Array<string> = []

    useEffect(() => {
        console.log(`Mounted parent panel ${props.panelKey}`)

        const setInitialState = async () => {
            setHasMappedSignalConfiguration(await isMappedConfiguration(props.frameFilter.namespace))
        }

        setInitialState()

        return () => {
            console.log(`Unmounted parent panel ${props.panelKey}`)
        }
    }, [])

    useEffect(() => {
        const optionalSetttings = {
            chartType: chartType,
            frameRate: frameRate,
            useSuggestedMinMax: useSuggestedMinMax,
            graphWidthMs: graphWidthInMs,
            allowDownsampling: allowDownsampling,
        } as OptionalFrameParentPanelSettings
        const settings = {
            signalFilters: props.signalFilters,
            frameFilter: props.frameFilter,
            optional: optionalSetttings,
        } as FrameParentPanelSettings
        const thisPanel = {
            name: props.panelKey,
            addedTimestamp: props.addedTimestamp,
            panelType: PanelType.FRAME_PARENT,
            key: props.panelKey,
            frameParentSettings: settings,
        } as Panel
        props.savePanelFunction(thisPanel)
    }, [chartType, frameRate, useSuggestedMinMax, graphWidthInMs, allowDownsampling])

    useEffect(() => {
        if (props.playbackState?.isPlaying() && previousPlaybackWasNotPlay.current) {
            console.log(`Clearing history from all charts`)
            clearSignalHistoryList()
        }
        previousPlaybackWasNotPlay.current = !props.playbackState?.isPlaying()
    }, [props.playbackState])

    const clearSignalHistoryList = () => {
        signalHistoryList.current.length = 0
    }

    const syncChartWithDelaySetting = (chart: Chart, delayInMs: number) => {
        /*
        if (chart?.config.options?.plugins?.streaming) {
            console.log(`Setting chart delay to ${delayInMs}. This will set the time to ${Date.now() - delayInMs}`)
            chart.config.options.plugins.streaming.delay = delayInMs
        }
        */
    }

    const syncChart = (mode: SyncMode, chart: Chart, playbackState: PlaybackState | undefined) => {
        let delayInMs = 0
        if (mode === SyncMode.TO_PLAYBACK_STATE && playbackState) {
            delayInMs =
                Date.now() -
                (playbackState.startTimestampMs() +
                    playbackState.currentOffsetDurationMs() -
                    playbackState.offsetWallClockVsSampleMs() -
                    GRAPH_DRAW_AHEAD_MS)
        }
        if (mode === SyncMode.TO_LAST_SIGNAL_RECEIVED) {
            const lastSignal = signalHistoryList.current[signalHistoryList.current.length - 1]
            delayInMs = Date.now() - lastSignal.signalValue.timestampeMs + GRAPH_DRAW_AHEAD_MS
        }
        syncChartWithDelaySetting(chart, delayInMs)
    }

    const syncCharts = (mode: SyncMode, playbackState: PlaybackState | undefined) => {
        props.signalFilters.forEach((filter) => {
            const key = constructContainerKey(filter.parentFrame.namespace, filter.signalName)
            const chart = Chart.getChart(key)
            if (chart !== undefined) {
                console.log(`Attempting to sync SIGNAL chart with mode=${mode.toString()}...`)
                syncChart(mode, chart, playbackState)
            }
        })
        const chart = Chart.getChart(constructContainerKey(props.frameFilter.namespace, props.frameFilter.frameName))
        if (chart) {
            console.log(`Attempting to sync FRAME chart with mode=${mode.toString()}...`)
            syncChart(mode, chart, playbackState)
        }
    }
    const getCardTitle = () => {
        return (
            <div className="p-1">
                <p className="m-0 text-start remotive-primary-60-color lh-sm text-truncate">
                    {props.frameFilter.frameName}
                </p>
                <p className="m-0 text-start remotive-smaller remotive-primary-40-color lh-1 text-truncate">
                    {props.frameFilter.namespace}
                </p>
            </div>
        )
    }

    const removeSignalFilter = (signalFilter: SignalFilter) => {
        const panelsAfterRemoval = props.panels.map((panel) => {
            if (
                panel.panelType === PanelType.FRAME_PARENT &&
                isFrameIdentical(panel.frameParentSettings!.frameFilter, props.frameFilter)
            ) {
                const signalsAfterRemoval = panel.frameParentSettings!.signalFilters.filter(
                    (panelSignal) => !isSignalIdentical(panelSignal, signalFilter)
                )
                const frameParentSettings = {
                    ...panel.frameParentSettings!,
                    signalFilters: signalsAfterRemoval,
                } as FrameParentPanelSettings
                const { name, panelType, key } = panel
                return { ...panel, frameParentSettings, name, panelType, key } as Panel
            }
            return panel
        })
        props.setPanelsFunction(panelsAfterRemoval)
        initializedGraphs = initializedGraphs.filter(
            (graphKey) =>
                graphKey !== constructContainerKey(signalFilter.parentFrame.namespace, signalFilter.signalName)
        )
    }

    const constructContainerKey = (namespace: string, signalName: string) => {
        return `${namespace}::${signalName}-container-key`
    }

    const getSignalGraphs = () => {
        return props.signalFilters.map((signalFilter) => {
            const key = constructContainerKey(signalFilter.parentFrame.namespace, signalFilter.signalName)
            return (
                <div key={signalFilter.signalName} className="mt-1">
                    <MultiTypeSignalContainer
                        onRefreshFunction={() => console.log('refresh')}
                        syncChartsFunction={() => syncCharts(SyncMode.TO_PLAYBACK_STATE, props.playbackState)}
                        isCloudBroker={props.isCloudBroker}
                        containerKey={key}
                        chartType={chartType}
                        frameRate={frameRate}
                        addSignalToHistoryFunction={addSignalToHistory}
                        signalHistoryList={signalHistoryList.current}
                        signalFilter={signalFilter}
                        playbackState={props.playbackState}
                        pauseLiveState={props.pauseLiveState}
                        removeContainerFunction={() => removeSignalFilter(signalFilter)}
                        isDownsamplingAllowed={allowDownsampling}
                        useSuggestedMinMax={useSuggestedMinMax}
                        graphWidthInMs={graphWidthInMs}
                    />
                </div>
            )
        })
    }

    const getFrameGraph = () => {
        const key = constructContainerKey(props.frameFilter.namespace, props.frameFilter.frameName)
        const signalFilter = {
            parentFrame: props.frameFilter,
            signalName: props.frameFilter.frameName,
            maxValue: 10, // We don't know this for frames
            minValue: 0, // We don't know this for frames
        } as SignalFilter
        return (
            <MultiTypeSignalContainer
                onRefreshFunction={() => console.log('refresh')}
                syncChartsFunction={() => syncCharts(SyncMode.TO_PLAYBACK_STATE, props.playbackState)}
                isCloudBroker={props.isCloudBroker}
                containerKey={key}
                chartType={chartType}
                frameRate={frameRate}
                addSignalToHistoryFunction={addSignalToHistory}
                signalHistoryList={signalHistoryList.current}
                signalFilter={signalFilter}
                playbackState={props.playbackState}
                pauseLiveState={props.pauseLiveState}
                isDownsamplingAllowed={allowDownsampling}
                useSuggestedMinMax={useSuggestedMinMax}
                graphWidthInMs={graphWidthInMs}
            />
        )
    }

    const addSignalToHistory = (signalValue: SignalValue, parsingType: ParsingType) => {
        if (signalHistoryList.current.length > MAX_HISTORY_COUNT) {
            signalHistoryList.current.splice(0, HISTORY_REMOVAL_COUNT)
        }
        signalHistoryList.current = signalHistoryList.current.concat({
            signalValue,
            parsingType,
        } as SignalWithParsingType)
    }

    const signalVisualizations = () => {
        return (
            <>
                <div className="mx-1">{getFrameGraph()}</div>
                <div className="mx-1">{getSignalGraphs()}</div>
            </>
        )
    }

    const popoverSettingsItem = (title: string, actionElement: ReactElement) => {
        return (
            <div className="d-flex justify-content-between align-items-center rounded px-2 py-1 m-1 remotive-primary-20-background">
                <p className="m-0 remotive-font-sm lexend-bold">{title}</p>
                <div className="d-flex justify-content-center align-items-center flex-row align-items-center">
                    {actionElement}
                </div>
            </div>
        )
    }

    const safeSetFrameRate = (newFrameRate: number) => {
        newFrameRate > 0 && newFrameRate < 31
            ? setFrameRate(newFrameRate)
            : toast.error(formattedToastMessage('Error', 'The frame rate must be between 1-30 Hz.'), {
                  autoClose: 10_000,
                  position: isIframe() ? 'top-center' : 'bottom-right',
              })
    }

    const safeSetGraphWidthInMs = (newGraphWidthInMs: number) => {
        newGraphWidthInMs > 0 && newGraphWidthInMs < 121_000
            ? setGraphWidthInMs(newGraphWidthInMs)
            : toast.error(formattedToastMessage('Error', 'The graph width must be between 1-120s.'), {
                  autoClose: 10_000,
                  position: isIframe() ? 'top-center' : 'bottom-right',
              })
    }

    const popover = (
        <Popover
            id="popover-basic"
            className="border-0 remotive-primary-0-background shadow rounded-4 me-2"
            style={{ maxWidth: 400 }}
        >
            <Popover.Body style={{ zIndex: '0 !important', width: 300 }} className="p-3 lexend-regular">
                <div>
                    <div>
                        {/* Disabled for now, we can recreate this by removing the useInterval in MultiTypeSignalContainer and recreating it with the new frame rate */}
                        {popoverSettingsItem(
                            'Chart update frequency',
                            <>
                                <button
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Decrease update frequency"
                                    onClick={() => safeSetFrameRate(frameRate - 1)}
                                >
                                    <Remove sx={{ fontSize: 16 }} />
                                </button>
                                <p className="fs-5 mb-0 px-1 remotive-font">{frameRate}</p>
                                <button
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Increase update frequency"
                                    onClick={() => safeSetFrameRate(frameRate + 1)}
                                >
                                    <Add sx={{ fontSize: 16 }} />
                                </button>
                            </>
                        )}
                    </div>
                    <div>
                        {/* Disabled for now, we can recreate this by removing the useInterval in MultiTypeSignalContainer and recreating it with the new frame rate */}
                        {popoverSettingsItem(
                            'Graph width in seconds',
                            <>
                                <button
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Decrease graph width"
                                    onClick={() => safeSetGraphWidthInMs(graphWidthInMs - 1000)}
                                >
                                    <Remove sx={{ fontSize: 16 }} />
                                </button>
                                <p className="fs-5 mb-0 px-1 remotive-font">{graphWidthInMs / 1000}</p>
                                <button
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Increase graph width"
                                    onClick={() => safeSetGraphWidthInMs(graphWidthInMs + 1000)}
                                >
                                    <Add sx={{ fontSize: 16 }} />
                                </button>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Chart type',
                            <>
                                <button
                                    className={`btn bg-transparent remotive-accessibility me-2 p-0 border-0 ${
                                        chartType === 'filled'
                                            ? 'remotive-success-60-color'
                                            : 'remotive-primary-80-color'
                                    }`}
                                    title="Filled line chart"
                                    onClick={() => setChartType('filled')}
                                >
                                    <FilledLine sx={{ fontSize: 22 }} />
                                </button>
                                <button
                                    className={`btn bg-transparent remotive-accessibility m-0 p-0 border-0 ${
                                        chartType === 'line' ? 'remotive-success-60-color' : 'remotive-primary-80-color'
                                    }`}
                                    onClick={() => setChartType('line')}
                                    title="Line chart"
                                >
                                    <Line sx={{ fontSize: 22 }} />
                                </button>
                            </>
                        )}
                    </div>
                    <div>
                        <div className="d-flex justify-content-between rounded px-2 py-1 m-1 remotive-primary-20-background">
                            <p className="remotive-font-sm m-0 lexend-bold">Use specified min/max</p>
                            <Form.Check
                                type="checkbox"
                                checked={useSuggestedMinMax}
                                className="m-0 pb-0 text-break remotive-small remotive-neutral-80-color"
                                onChange={(element) => {
                                    const checkbox = element.target as HTMLInputElement
                                    setUseSuggestedMinMax(checkbox.checked)
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between rounded px-2 py-1 m-1 remotive-primary-20-background">
                            <p className="remotive-font-sm m-0 lexend-bold">Allow downsampling</p>
                            <Form.Check
                                type="checkbox"
                                checked={allowDownsampling}
                                className="m-0 pb-0 text-break remotive-small remotive-neutral-80-color"
                                onChange={(element) => {
                                    const checkbox = element.target as HTMLInputElement
                                    setAllowDownsampling(checkbox.checked)
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="d-flex justify-content-center mt-2">
                            {hasMappedSignalConfiguration && (
                                <button
                                    className="btn remotive-btn-primary remotive-btn-sm p-1 border-0 d-flex align-items-center"
                                    onClick={() =>
                                        viewSignalSource(props.frameFilter.frameName, props.frameFilter.namespace)
                                    }
                                    title="Show source of mapped signal"
                                >
                                    <CodeIcon sx={{ fontSize: 20 }} />
                                    <span className="d-none d-lg-inline-block ms-1">Source</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <Card className="shadow rounded border-0 m-1 mx-0 pb-2">
                <div className="d-flex justify-content-between p-0 m-0 flex-nowrap">
                    <div className="d-flex d-inline-block text-truncate flex-nowrap">{getCardTitle()}</div>
                    <div className="d-flex d-inline-block flex-nowrap">
                        <button
                            onClick={() => setShowSignalValueHistoryModal(true)}
                            className="btn remotive-btn-secondary remotive-btn-sm p-1 border-0 d-flex align-items-center"
                        >
                            <>
                                <History sx={{ fontSize: 20 }} />
                                <p className="m-0 ms-1 d-none d-lg-block">History</p>
                            </>
                        </button>
                        <button className="btn remotive-btn-secondary remotive-btn-sm p-1 border-0">
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                show={showPopover}
                                onToggle={(newState: boolean) => setShowPopover(newState)}
                                placement="left"
                                overlay={popover}
                            >
                                <div className="d-flex align-items-center">
                                    <Settings sx={{ fontSize: 20 }} />
                                    <p className="m-0 ms-1 d-none d-lg-block">Settings</p>
                                </div>
                            </OverlayTrigger>
                        </button>
                        <button
                            onClick={() => props.removePanelFunction(props.panelKey)}
                            className="btn remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center" title="Close entire panel">
                                <Close sx={{ fontSize: 24 }} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="mx-1">{signalVisualizations()}</div>
            </Card>
            <SignalValueHistoryModal
                show={showSignalValueHistoryModal}
                signalHistoryList={signalHistoryList.current}
                handleCloseFunction={() => setShowSignalValueHistoryModal(false)}
            />
        </>
    )
}
