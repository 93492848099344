import { useEffect, useState } from 'react'
import { Modal, Tooltip, OverlayTrigger, Form } from 'react-bootstrap'
import Info from '@mui/icons-material/InfoRounded'
import Close from '@mui/icons-material/CloseRounded'

import { Namespace, PlaybackState, PlaybackStateFile } from '../../api/BrokerApi/types'
import { getAvailableFiles, getPlayableNamespaces } from '../../services/BrokerService'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import { isIframe } from '../../utils/CloudDetails'

interface SelectFileModalProps {
    show: boolean
    handleCloseFunction: () => void
    playSelectedFilesFunction: (filesToPlay: Array<PlaybackStateFile>) => void
    availablePlaybackFiles: () => Promise<Array<PlaybackStateFile>>
    playbackState: PlaybackState | undefined
    refreshPanels: Function
}

const TOOLTIP_READY = (
    <Tooltip id="tooltip-ready-files">
        Files that are ready to be played on your broker, pressing "use selected" will replace the current data stream.
        You can play multiple recordings simultaneously if they are using different namespaces
    </Tooltip>
)

function SelectFileModal(props: SelectFileModalProps) {
    // All available files and namespaces
    const [allNamespaces, setAllNamespaces] = useState<Array<Namespace>>([])
    // Currently available files and namespaces
    const [availableFiles, setAvailableFiles] = useState<Array<PlaybackStateFile>>([])
    const [availableNamespaces, setAvailableNamespaces] = useState<Array<Namespace>>([])
    // Currently selected file and namespace
    const [selectedNamespace, setSelectedNamespace] = useState<Namespace>()
    const [selectedFile, setSelectedFile] = useState<PlaybackStateFile>()
    // All files ready for playback (a file path and namespace combination has been selected)
    const [readyFiles, setReadyFiles] = useState<Array<PlaybackStateFile>>([])

    useEffect(() => {
        console.log('Mounting select recording modal')
    }, [])

    useEffect(() => {
        const getAllFiles = async () => {
            const files = await getAvailableFiles()
            setAvailableFiles(files)
        }

        const getAllNamespaces = async () => {
            const namespaces = await getPlayableNamespaces()
            setAllNamespaces(namespaces)
            setAvailableNamespaces(namespaces)
        }

        getAllFiles()
        getAllNamespaces()
    }, [props.show])

    useEffect(() => {
        trySetReadyFile()
    }, [selectedFile, selectedNamespace])

    const removeReadyFile = (file: PlaybackStateFile) => {
        // It should not be possible to get undefined here, but if we do there is nothing we can do except ignore the removal
        const namespaceToReadd = allNamespaces.find((namespace) => namespace.name === file.namespace())
        if (namespaceToReadd !== undefined) {
            const readyFilesAfterRemoval = readyFiles.filter(
                (readyFile) => readyFile.namespace() !== file.namespace() && readyFile.path() !== file.path()
            )
            const availabelFilesAfterAddition = [...availableFiles, file]
            const availableNamespacesAfterAddition = [...availableNamespaces, namespaceToReadd]
            setAvailableNamespaces(availableNamespacesAfterAddition)
            setAvailableFiles(availabelFilesAfterAddition)
            setReadyFiles(readyFilesAfterRemoval)
        }
    }

    const renderReadyFiles = () => {
        if (readyFiles.length > 0) {
            return readyFiles.map((file) => {
                return (
                    <div key={`${file.namespace()}${file.path()}`}>
                        <button
                            className="btn ms-1 mt-0 p-1 border-0 pb-0 pt-0 remotive-primary-20-background"
                            onClick={() => removeReadyFile(file)}
                            data-toggle="tooltip"
                            data-placement="top"
                        >
                            <div className="d-flex justify-content-between remotive-neutral-80-color rounded remotive-primary-20-background p-1 m-0">
                                <div>
                                    <Close className="remotive-primary-60-color" sx={{ fontSize: 15 }} />
                                </div>
                                <div className="p-1">
                                    <p className="text-break remotive-small m-0">
                                        {`${file.namespace()} > ${file.path()}`}
                                    </p>
                                </div>
                            </div>
                        </button>
                    </div>
                )
            })
        }
        return <p className="remotive-small ms-4">Select at least one namespace and one file to proceed</p>
    }

    const renderFiles = () => {
        if (availableFiles.length > 0) {
            return availableFiles.map((file) => {
                return (
                    <div key={`${file.path()}`}>
                        <Form.Check
                            type="radio"
                            className="text-break remotive-small remotive-neutral-80-color rounded remotive-primary-20-background p-2 ps-4 mb-1 mx-1"
                            label={`${file.path()}`}
                            checked={selectedFile?.path() === file.path()}
                            onChange={(element) => {
                                const radio = element.target as HTMLInputElement
                                if (radio.checked) {
                                    setSelectedFile(file)
                                    trySetReadyFile()
                                } else {
                                    setSelectedFile(undefined)
                                }
                            }}
                        />
                    </div>
                )
            })
        }
        return <p className="remotive-small ms-4">No files available...</p>
    }

    const renderNamespaces = () => {
        if (availableNamespaces.length > 0) {
            return availableNamespaces
                .filter((namespace) => namespace.type !== 'scripted')
                .map((namespace) => {
                    return (
                        <div key={`${namespace.name}`}>
                            <Form.Check
                                type="radio"
                                className="text-break remotive-small remotive-neutral-80-color rounded remotive-primary-20-background p-2 ps-4 mb-1 mx-1"
                                label={`${namespace.name}`}
                                defaultChecked={selectedNamespace?.name === namespace.name}
                                onChange={(element) => {
                                    const radio = element.target as HTMLInputElement
                                    if (radio.checked) {
                                        setSelectedNamespace(namespace)
                                    } else {
                                        setSelectedNamespace(undefined)
                                    }
                                }}
                            />
                        </div>
                    )
                })
        }
        if (readyFiles.length > 0 && availableFiles.length > 0) {
            return (
                <p className="remotive-small ms-4">
                    Add more namespaces in your configuration to play additional files simultaneously
                </p>
            )
        }
        return <p className="remotive-small ms-4">No namespaces available...</p>
    }

    const validateInputFields = (files: Array<PlaybackStateFile>): boolean => {
        if (selectedFile !== undefined && selectedNamespace === undefined && availableNamespaces.length > 0) {
            toast.error(formattedToastMessage('You must also select a namespace'))
            return false
        }
        if (selectedNamespace !== undefined && selectedFile === undefined && availableFiles.length > 0) {
            toast.error(formattedToastMessage('You must also select a file'))
            return false
        }
        if (files.length <= 0) {
            toast.error(formattedToastMessage('You must have at least one file and one namespace selected'))
            return false
        }
        return true
    }

    const playAndClose = async () => {
        if (validateInputFields(readyFiles)) {
            try {
                await props.playSelectedFilesFunction(readyFiles)
                props.refreshPanels()
                close()
            } catch (e: any) {
                console.log(JSON.stringify(e))
                toast.error(
                    formattedToastMessage('Failed to play files', 'There was an issue when playing your selected files')
                )
            }
        }
    }

    const close = () => {
        props.handleCloseFunction()
        setReadyFiles([])
    }

    const trySetReadyFile = () => {
        if (selectedNamespace !== undefined && selectedFile !== undefined) {
            const readyFile = {
                path: () => selectedFile.path(),
                namespace: () => selectedNamespace.name,
            } as PlaybackStateFile
            const availableFilesAfterRemoval = availableFiles.filter((file) => file.path() !== selectedFile.path())
            const availableNamespacesAfterRemoval = availableNamespaces.filter(
                (namespace) => namespace.name !== selectedNamespace.name
            )
            setAvailableFiles(availableFilesAfterRemoval)
            setAvailableNamespaces(availableNamespacesAfterRemoval)
            setReadyFiles([...readyFiles, readyFile])
            setSelectedFile(undefined)
            setSelectedNamespace(undefined)
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={close} size="lg">
                <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                    <Modal.Title className="lexend-regular text-light">Select recording(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="mb-5">
                            <div className="mb-2">
                                <div className="d-flex justify-content-start d-inline ms-1">
                                    <div>
                                        <p className="m-0">1. Select a file</p>
                                        <p className="m-0 mb-1 remotive-font-sm text-secondary">
                                            Select a file with recorded signals
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="justify-content-start"
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: '100px',
                                    }}
                                >
                                    <div className="d-flex flex-wrap">{renderFiles()}</div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex justify-content-start d-inline ms-1">
                                    <div>
                                        <p className="m-0">2. Select a namespace</p>
                                        <p className="m-0 mb-1 remotive-font-sm text-secondary">
                                            Select a namespace to replay the recorded signals on
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="justify-content-start"
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: '100px',
                                    }}
                                >
                                    <div className="d-flex flex-wrap">{renderNamespaces()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1">
                            <div className="d-flex justify-content-start d-inline">
                                <div>
                                    <OverlayTrigger placement="right" overlay={TOOLTIP_READY}>
                                        <Info className="align-top remotive-primary-40-color" sx={{ fontSize: 22 }} />
                                    </OverlayTrigger>
                                </div>
                                <div>
                                    <p className="ms-1 m-1 mt-0">Files ready to use:</p>
                                </div>
                            </div>
                            <div
                                className="justify-content-start"
                                style={{
                                    overflowY: 'scroll',
                                    maxHeight: '100px',
                                }}
                            >
                                <div className="d-flex flex-wrap">{renderReadyFiles()}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button className="btn remotive-btn remotive-btn-primary" onClick={close}>
                        Cancel
                    </button>
                    <button
                        disabled={readyFiles.length <= 0}
                        className="btn remotive-btn remotive-btn-success"
                        onClick={playAndClose}
                    >
                        Use selected
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SelectFileModal
